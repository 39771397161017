import LazyLoad from "vanilla-lazyload";

export function ajaxFilter() {

    var params = {
        catID: -1,
        text: "",
        postTypeEl: -1,
        typeProId: -1,
        postsToExclude: [],
        pageId: -1,
        page_num: 1,
        is_home: false,
        from_period: "",
        to_period: ""
    }

    // var catId = -1;
    // var text  = "";
    // var postTypeEl = -1;
    // var typeProId = -1;
    // var postsToExclude = [];
    // var pageId = -1
    // var page_num = 1
    // var is_home = false;
    // var from_period = "";
    // var to_period = "";

    if (jQuery('#home').length != 0) {
        // is_home = true;
        params.is_home = true
    }

    if (jQuery('.post-type-page').length != 0) {
        // pageId = jQuery('.post-type-page').attr("id").split('-')[1];
        params.pageId = jQuery('.post-type-page').attr("id").split('-')[1];
    }

    if (jQuery('#hidden_post_type').length != 0) {
        // postTypeEl = jQuery('#hidden_post_type').val();
        params.postTypeEl = jQuery('#hidden_post_type').val();
    }

    const ajax_filter = function(params) {
        const action = "filter_posts";
        
        jQuery.ajax({
            url: "/wp/wp-admin/admin-ajax.php",
            type: "POST",
            data: {
                action: action,
                pagination_id: params.page_num,
                post_type: params.postTypeEl,
                catId: params.catID,
                textSearched: params.text,
                typeProId: params.typeProId,
                pageId: params.pageId,
                postsToExclude: params.postsToExclude,
                isHome: params.is_home,
                fromPeriod: params.from_period,
                toPeriod: params.to_period
            },
            beforeSend: function() {

                jQuery('.loader-container').toggleClass('hidden')
                jQuery('.loader-container').toggleClass('flex')
                
                let scroll_to = ".archive_posts"
                jQuery('html, body').animate({
                    scrollTop: jQuery(scroll_to).offset().top - 100
                }, 1000)
            },
            success: function (data) {
                jQuery("#container-main-dynamic").replaceWith(data);

                var lazyLoad = new LazyLoad;

                if (jQuery('.element-pagination').length != 0) {
                    jQuery('.element-pagination').on('click', function() {
                        params.page_num = jQuery(this).data('pnum');
                        params.postTypeEl = jQuery(this).data('posttype')
                        // ajax_filter(idCat, post_type, page_num, searchedText, idTypePro, postsToExclude, from_period, to_period);
                        ajax_filter(params)
                    })
                }
                annuaireCardAnimation();
                setTimeout(() => {
                    jQuery('.loader-container').toggleClass('hidden')
                    jQuery('.loader-container').toggleClass('flex')
                    jQuery('#container-filters').removeClass('active')
                }, 500);
            }
        }).then(function(){
            // let scroll_to = ".archive_posts"
            
            // jQuery('html, body').animate({
            //     scrollTop: jQuery(scroll_to).offset().top - 100
            // }, 1000)
        });
    }

    const annuaireCardAnimation = () => {
        jQuery('.button-annuaire').on('click', function() {
          let postId = jQuery(this).data('id');
          jQuery(this).toggleClass('active');
          jQuery(`.informations-annuaire-${postId}`).slideToggle()
          jQuery(`.description-annuaire-${postId}`).toggleClass('hidden')
        });
    }

    annuaireCardAnimation();

    jQuery('#search-element').on('keypress', function(event) {
        if (event.key === "Enter"){
            params.text = jQuery(this).val();
            // text = jQuery(this).val();
            params.page_num = 1
            // ajax_filter(catId, postTypeEl, page_num, text, typeProId, postsToExclude, from_period, to_period);
            ajax_filter(params)
        }
    })

    const post_in_news = jQuery('#in-news__posts > div');
    if (post_in_news.length != 0) {
        post_in_news.each((index, el) => {
    
            let id = el.getAttributeNode('id').value.split('-')[1]
            params.postsToExclude.push(id)
        })
    }

    if (jQuery('.element-pagination').length != 0) {
        jQuery('.element-pagination').on('click', function() {
            params.page_num = jQuery(this).data('pnum');
            params.postTypeEl = jQuery(this).data('posttype')
            // ajax_filter(catId, postTypeEl, page_num, text, typeProId, postsToExclude, from_period, to_period);
            ajax_filter(params)
        });
    }


    // jQuery('.cat-item-filter').on('click', function() {
    //     params.catID = jQuery(this).data('value');
    //     params.page_num = 1
    //     if (params.catID != -1) {
    //         jQuery('.cat-item-filter-selected').addClass('hidden')
    //         jQuery(`#cat-selected-${params.catID}`).removeClass('hidden')
    //     }
    //     // ajax_filter(catId, postTypeEl, page_num, text, typeProId, postsToExclude, from_period, to_period);
    //     ajax_filter(params)
    // });

    // jQuery('.cat-item-filter-selected').on('click', function() {
    //     params.catID = -1
    //     params.page_num = 1
    //     jQuery('.cat-item-filter-selected').addClass('hidden')
    //     ajax_filter(params)
    // })
    jQuery('.cat-item-filter').on('click', function() {
        params.catID = jQuery(this).data('value');
        params.page_num = 1;
    
        // Hide all cat-item-filter-selected elements first
        jQuery('.cat-item-filter-selected').addClass('hidden');
    
        // Show the selected cat-item-filter-selected element, if it's not -1
        if (params.catID != -1) {
            jQuery(`#cat-selected-${params.catID}`).removeClass('hidden');
        }
    
        // Call your ajax filter with the updated parameters
        ajax_filter(params);
    });
    
    jQuery('.cat-item-filter-selected').on('click', function() {
        params.catID = -1;
        params.page_num = 1;
    
        // Hide all selected categories (basically reset state)
        jQuery('.cat-item-filter-selected').addClass('hidden');
    
        // Call your ajax filter with the updated parameters
        ajax_filter(params);
    });
    
    // jQuery('.cat-item-filter-domaine').on('click', function() {
    //     domaineID = jQuery(this).data('value');
    //     ajax_filter(catId, postTypeEl, 1, period, text, typeProId, domaineID);
    // });

    // jQuery('.item-pro').on('click', function() {
    //     typeProId = jQuery(this).data('id');
    //     ajax_filter(catId, postTypeEl, 1, period, text, typeProId, domaineID);
    // })

    jQuery('#search-period').on('click', function() {
        params.from_period = jQuery('#period-from').val()
        params.to_period = jQuery('#period-to').val()

        // ajax_filter(catId, postTypeEl, page_num, text, typeProId, postsToExclude, from_period, to_period);
        ajax_filter(params)
    })

    jQuery('#filter-closer').on("click", function() {
        jQuery('#container-filters').removeClass('active')
    })
    console.log('ajax-filter.js');
}