import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";
import "jquery.marquee";
import init from "./common";

init();
// Init Lazy load instance
var lazyLoad = new LazyLoad();
function sliderLazy() {
  lazyLoad.update();
}

const update_inner_html = (content, container) => {
  jQuery(container).html(content);
};

const slider_cursor = (
  range_input,
  slider,
  html_to_update,
  controls_container
) => {
  let index = 1;
  jQuery(range_input).on("change", function () {
    index = jQuery(this).val();
    slider.goTo(parseInt(index) - 1);
    update_inner_html(index, html_to_update);
  });

  if (jQuery(controls_container).length != 0) {
    jQuery(controls_container).on("click", function () {
      const infos_slider = slider.getInfo();
      index = infos_slider.index + 1;
      console.log(infos_slider);
      jQuery(range_input).val(index);
      update_inner_html(index, html_to_update);
    });
  }

  slider.events.on("transitionEnd", function (info) {
    index = info.index + 1;
    jQuery(range_input).val(index);
    update_inner_html(index, html_to_update);
  });
};

const display_controls_actualities =
  jQuery("#home-actualities__posts-controls").length != 0;
const display_controls_agenda =
  jQuery("#home-agenda__posts-controls").length != 0;
const display_controls_projects =
  jQuery("#home-project__posts-controls").length != 0;
const display_controls_publications =
  jQuery("#home-publications__posts-controls").length != 0;

var sliderActualites = tns({
  container: "#home-actualites__posts-slider",
  items: 1,
  center: true,
  loop: false,
  fixedWidth: 320,
  autoplayButtonOutput: false,
  controls: display_controls_actualities,
  navContainer: "#home-actualities__posts-nav-hidden",
  swipeAngle: false,
  mouseDrag: true,
  controlsContainer: "#home-actualities-laptop__posts-controls",
  responsive: {
    768: {
      center: false,
    },
    1280: {
      navContainer: "#home-actualities-laptop__posts-nav-hidden",
      fixedWidth: 420,
    },
  },
});

sliderActualites.events.on("transitionEnd", sliderLazy);
slider_cursor(
  "#range-actualities",
  sliderActualites,
  "#current-actualities-selected-number",
  "#home-actualities__posts-controls"
);

slider_cursor(
  "#range-actualities-laptop",
  sliderActualites,
  "#current-actualities-laptop-selected-number",
  "#home-actualities-laptop__posts-controls"
);

if (window.innerWidth <= 1279) {
  console.log(window.innerWidth);
  var sliderAgenda = tns({
    container: "#home-agenda__posts-slider",
    items: 1,
    center: true,
    gutter: 20,
    loop: false,
    fixedWidth: 320,
    autoplayButtonOutput: false,
    controls: display_controls_agenda,
    navContainer: "#home-agenda__posts-nav-hidden",
    swipeAngle: false,
    mouseDrag: true,
    controlsContainer: "#home-agenda__posts-controls",
    responsive: {
      768: {
        center: false,
      },
    },
  });

  sliderAgenda.events.on("transitionEnd", sliderLazy);

  slider_cursor(
    "#range-agenda",
    sliderAgenda,
    "#current-agenda-selected-number",
    "#home-agenda__posts-controls"
  );
}

var sliderProjects = tns({
  container: "#project-slider",
  items: 1,
  center: false,
  loop: false,
  fixedWidth: 320,
  autoplayButtonOutput: false,
  controls: display_controls_projects,
  navContainer: "#home-project__posts-nav-hidden",
  swipeAngle: false,
  mouseDrag: true,
  gutter: 40,
  controlsContainer: "#home-project-laptop__posts-controls",
  responsive: {
    1280: {
      fixedWidth: 1400,
      navContainer: "#home-project-laptop__posts-nav-hidden",
      gutter: 400,
    },
  },
});

sliderProjects.events.on("transitionEnd", sliderLazy);

slider_cursor(
  "#range-project",
  sliderProjects,
  "#current-project-selected-number",
  "#home-project__posts-controls"
);

slider_cursor(
  "#range-project-laptop",
  sliderProjects,
  "#current-project-laptop-selected-number",
  "#home-project-laptop__posts-controls"
);

if (window.innerWidth <= 1279) {
  var sliderPublications = tns({
    container: "#home-publications__posts-slider",
    items: 1,
    center: false,
    loop: true,
    gutter: 50,
    fixedWidth: 256,
    autoplayButtonOutput: false,
    controls: display_controls_publications,
    navContainer: "#home-publications__posts-nav-hidden",
    swipeAngle: false,
    controlsContainer: "#home-publications__posts-controls",
  });

  sliderPublications.events.on("transitionEnd", sliderLazy);
  slider_cursor(
    "#range-publications",
    sliderPublications,
    "#current-publications-selected-number",
    "#home-publications__posts-controls"
  );
} else {
  console.log("carlos");
  update_inner_html(1, "#current-publications-selected-number");
  jQuery("#range-publications").val(1);
}

const move_publications = (index, from_range = false) => {
  jQuery(`.active-slide, .slide-${index}`).toggleClass(
    "active-slide inactive-slide"
  );

  const id = jQuery(".active-slide").data("id");

  jQuery(".info-pub-home > .infos-titles").removeClass("active");
  jQuery(`#info-pub-${id}`).addClass("active");

  if (!from_range) {
    jQuery("#range-publications").val(index);
  }

  update_inner_html(index, "#current-publications-selected-number");
  index = parseInt(index);

  switch (index) {
    case 1:
      jQuery(".base-last").addClass("come-second").removeClass("come-first");
      jQuery(".base-first").addClass("come-last").removeClass("come-second");
      jQuery(".base-second").addClass("come-first").removeClass("come-last");
      break;
    case 2:
      jQuery(".base-last").addClass("come-first").removeClass("come-second");
      jQuery(".base-first").addClass("come-second").removeClass("come-last");
      jQuery(".base-second").addClass("come-last").removeClass("come-first");
      break;
    case 3:
      jQuery(".base-last").removeClass("come-first come-second");
      jQuery(".base-first").removeClass("come-second come-last");
      jQuery(".base-second").removeClass("come-last come-first");
      break;

    default:
      break;
  }
};

jQuery("#controls-publications__next").on("click", function () {
  let index = jQuery(".active-slide").data("index");
  console.log(index);
  if (jQuery(`.slide-${index + 1}`).length != 0) {
    index = index + 1;
  } else {
    index = jQuery(".base-first").data("index");
  }

  move_publications(index);
});
jQuery("#controls-publications__prev").on("click", function () {
  let index = jQuery(".active-slide").data("index");
  if (jQuery(`.slide-${index - 1}`).length != 0) {
    index = index - 1;
  } else {
    index = jQuery(".base-last").data("index");
  }

  move_publications(index);
});

jQuery("#range-publications").on("change", function () {
  const index = jQuery(this).val();
  move_publications(index, true);
});

jQuery("#header-flash__content").marquee({
  // duration: 10000,
  speed: 120,
  gap: 30,
  delayBeforeStart: 0,
  direction: "left",
  duplicated: false,
  pauseOnHover: true,
  startVisible: false,
});
