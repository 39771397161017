import { ajaxFilter } from "./ajax/ajax-filter";

function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

export default function init() {
  // access button link Orange confort
 

  jQuery("#access-link").on("click", function () {
    eventFire(document.getElementById("uci_link"), "click");
  });

  const menuItem = jQuery(".menu-item");
  menuItem.on("click", function () {
    const menuItem_id = jQuery(this).data("menu");
    if (jQuery("#" + menuItem_id).hasClass("visible")) {
      jQuery("#" + menuItem_id).removeClass("visible");
    } else {
      jQuery(".children-menu").removeClass("visible");
      jQuery("#" + menuItem_id).addClass("visible");
    }
    if (jQuery(".children-menu").hasClass("visible")) {
      jQuery(".menu-overlay").addClass("visible");
      jQuery(".bandeau-int").addClass("big");
    } else {
      jQuery(".menu-overlay").removeClass("visible");
      jQuery(".bandeau-int").removeClass("big");
    }
  });

  jQuery(".menu-open").on("click", function () {
    jQuery(".menu").toggleClass("active");
  });

  jQuery(window).on("scroll", function () {
    if (jQuery(this).scrollTop() > 300) {
      jQuery(".main-menu").removeClass("absolute");
      jQuery(".main-menu").addClass("fixed");
    } else {
      jQuery(".main-menu").addClass("absolute");
      jQuery(".main-menu").removeClass("fixed");
    }
  });

  // Listes Depliables
  jQuery(".liste-depliable__title").on("click", function () {
    jQuery(this).toggleClass("active");
    // Shun 03/09/2024 SVG + qui ne s'enlevait pas quand on intéragissait avec la liste déplliable 
    jQuery(this).find(".svg-plus-list").toggleClass("opacity-0");
    //
    jQuery(this).next(".liste-depliable__content").slideToggle();
  });

  jQuery(document).on("click", 'a[href^="#"]', function (event) {
    event.preventDefault();
    console.log("click");
    jQuery("html, body").animate(
      {
        scrollTop: jQuery(jQuery.attr(this, "href")).offset().top - 100,
      },
      600
    );
  });

  // Gtranslate

  function cleartimer() {
    setTimeout(function () {
      window.clearInterval(myVar);
    }, 1000);
  }
  function myTimer() {
    if (jQuery(".goog-te-combo option:first").length) {
      jQuery(".goog-te-combo option:contains('Sélectionner une langue')").html(
        "FR"
      );
      jQuery(".goog-te-combo option:contains('Anglais')").html("EN");
      jQuery(".goog-te-combo option:contains('Allemand')").html("DE");
      jQuery(".goog-te-combo option:contains('Espagnol')").html("ES");
      cleartimer();
    }
    jQuery(".goog-te-combo").on("change", function () {
      console.log("test");
      jQuery(".goog-te-combo option:contains('Sélectionner une langue')").html(
        "FR"
      );
      jQuery(".goog-te-combo option:contains('Anglais')").html("EN");
      jQuery(".goog-te-combo option:contains('Allemand')").html("DE");
      jQuery(".goog-te-combo option:contains('Espagnol')").html("ES");
      cleartimer();
    });
  }
  var myVar = setInterval(function () {
    myTimer();
  }, 300);


  jQuery('#header-navigation__menu-jesuis').on('click', function() {
    jQuery('#jesuis-container').toggleClass('active')
    jQuery('#header-hero__access').toggleClass('inactive')
  })

  if (window.innerWidth < 768) {
    if (jQuery('#header-hero__search').hasClass('active')) {
      jQuery('#header-hero__search').removeClass('active')
    }
  }
  
  jQuery('#header-navigation__menu-search').on('click', function() {
    jQuery('#header-hero__search').toggleClass('active')
    if (window.innerWidth < 768) {
      jQuery('#header-hero__access').toggleClass('inactive')
    }
  })

  const buttonDemarches = jQuery("#header-demarches");
  buttonDemarches.on("click", function () {
    jQuery("#header-demarches-container").toggleClass("active");
  });

  jQuery("#close-demarches-container").on("click", function() {
    jQuery("#header-demarches-container, .demarche-item, #header-demarches-container-subpages, .container-subpages, .container-subchilds, .page-title-demarche, #come-back-demarche").removeClass("active not-active active-subchild title-active demarche-active");
    jQuery('#header-demarches-container').css('background-color', '#E48F00')
  })

  jQuery('.demarche-item').on('click', function() {
    const id = jQuery(this).data('id');
    const url = jQuery(this).data('url')

    if (jQuery(`#demarche-child-parent-${id}`).length == 0) {
      window.location.href = url;
      return;
    }

    if (jQuery(this).hasClass('active')) {
      jQuery('.demarche-item, #header-demarches-container-subpages, .page-title-demarche, .container-subchilds').removeClass('active not-active')
      jQuery('#header-demarches-container').css('background-color', '#E48F00')
      jQuery('#come-back-demarche').removeClass('demarche-active')
    } else {
      const color = jQuery(this).data('color')
      jQuery('#header-demarches-container').css('background-color', `${color}`)
      jQuery('#header-demarches-container-subpages').addClass('active')
      jQuery(this).addClass('active')
      jQuery('.demarche-item').not(jQuery(this)).addClass('not-active')
      jQuery('#come-back-demarche').addClass('demarche-active active')
    }
    jQuery(`#demarche-child-parent-${id}`).addClass('active')
  })

  jQuery('.page-title-demarche').on('click', function() {
    const id = jQuery(this).data('id')
    if (jQuery(this).hasClass('active')) {
      jQuery('.page-title-demarche, .container-subchilds').removeClass('active not-active')
      jQuery('.demarche-item.active, #header-demarches-container-subpages, .container-subpages').removeClass('active-subchild')
      jQuery('#come-back-demarche').removeClass('demarche-active')

    } else {
      jQuery(this).toggleClass('active').removeClass('not-active')
      jQuery('.demarche-item.active, #header-demarches-container-subpages, .container-subpages').addClass('active-subchild')
      jQuery('.container-subchilds').removeClass('active')
      jQuery(`#demarche-subchild-parent-${id}`).toggleClass('active')
      jQuery('.page-title-demarche').not(jQuery(this)).addClass('not-active').removeClass('active')
      jQuery('#come-back-demarche').addClass('title-active').removeClass('demarche-active')
    }
  })

  jQuery('#come-back-demarche').on("click", function() {
    if (jQuery(this).hasClass('title-active')) {
      jQuery('.page-title-demarche, .container-subchilds').removeClass('active not-active')
      jQuery('.demarche-item.active, #header-demarches-container-subpages, .container-subpages').removeClass('active-subchild')
      jQuery(this).removeClass('title-active').addClass('demarche-active')

    }
    else if (jQuery(this).hasClass('demarche-active')) {
      jQuery('.demarche-item, #header-demarches-container-subpages, .page-title-demarche, .container-subchilds, .container-subpages').removeClass('active not-active')
      jQuery('#header-demarches-container').css('background-color', '#E48F00')
      jQuery(this).removeClass('demarche-active active')
    } 
  })

  jQuery('.primary-menu-item').on('click', function() {
    if (jQuery(this).hasClass('active')) {
      jQuery('#header-hero__submenu, #header-bandeau__submenu, .primary-menu-item').removeClass('active');
      return;
    }

    jQuery('.primary-menu-item, .item-submenu, .submenu, .submenu-childs').removeClass('active')
    jQuery(this).addClass('active')

    jQuery('#header-hero__submenu, #header-bandeau__submenu').addClass('active')
    const id = jQuery(this).data('id')
    jQuery('.item-submenu').removeClass('active-display')
    jQuery(`.item-parent-${id}`).addClass('active-display')
  })

  jQuery(document).on('click', function(event) {
    if (!jQuery(event.target).closest('.primary-menu-item, #header-hero__submenu, .submenu, .submenu-childs').length) {
        jQuery('#header-hero__submenu, #header-bandeau__submenu, .primary-menu-item').removeClass('active');
    }
  });

  jQuery('.item-submenu').on('click', function() {
    if (jQuery(this).hasClass('active')) {
      jQuery('.item-submenu, .submenu-childs, .item-submenu-childs, .submenu').removeClass('active')
      return;
    }

    jQuery('.item-submenu-childs, .item-submenu').removeClass('active')
    jQuery(this).addClass('active')
    const id = jQuery(this).data('id')
    jQuery(`#item-submenu-childs-parent-${id}, .submenu-childs, .submenu`).addClass('active')
  })



  jQuery('#header-navigation__menu-burger').on('click', function() {
    jQuery('#header-menu-primary-responsive').addClass('active')
  })

  jQuery('.close-menu').on('click', function() {
    jQuery('#header-menu-primary-responsive').removeClass('active')
  })

  jQuery('.title-primary-responsive-menu').on("click", function() {
    jQuery(this).addClass('active')
    jQuery('.title-primary-responsive-menu').not(jQuery(this)).addClass('inactive')
    jQuery('#header-menu-primary-responsive').addClass('active-child')
    jQuery('.close-menu, .come-back').toggleClass('active')
    const id = jQuery(this).data('id')
    jQuery(`#responsive-menu-parent-${id}`).addClass('active')
  })

  jQuery('.come-back').on('click', function() {
    jQuery('.title-primary-responsive-menu, .container-children-responsive-menu').removeClass('active inactive')
    jQuery('.close-menu, .come-back').toggleClass('active')
    jQuery('#header-menu-primary-responsive').removeClass('active-child')
  })

  jQuery('.c-letter__item').on('mouseenter', function() {
    const index = jQuery(this).data('index');
    jQuery(this).addClass('active')
    jQuery('.c-letter__title').removeClass('active')
    jQuery(`#access-${index}`).addClass('active')
  }).on('mouseleave', function() {
    jQuery('.c-letter__title').removeClass('active')
    jQuery(`#access-primary`).addClass('active')
    jQuery(this).removeClass('active')
  })

  jQuery(window).on("scroll", function() {
    let top = jQuery('#contact-circle').offset().top
    if (top > 700) {
      jQuery('#contact-circle, #c-letter-container').addClass('inactive')
      jQuery('#scroll-top-button').removeClass('opacity-0')
    } else {
      jQuery('#scroll-top-button').addClass('opacity-0')
      jQuery('#contact-circle, #c-letter-container').removeClass('inactive')
    }
  })

  jQuery('#contact-circle, #c-letter-container').on('mouseenter', function() {
    jQuery(this).removeClass('inactive')
  })

  jQuery('.open-filters').on("click", function() {
    jQuery('#container-filters').addClass('active')
  })
  
  ajaxFilter();

  jQuery('#search-input').on('focus', function() {
    jQuery("#header-hero__search, .container-props-search").addClass('activated')
  }).on('blur', function() {
    setTimeout(() => {
      jQuery("#header-hero__search, .container-props-search").removeClass('activated')
    }, 500);
  })

  jQuery('.button-props-search').on('click', function() {
    const value = jQuery(this).data('value')
    jQuery('#search-input').val(value)
    jQuery('#searchform_site').submit()
  })
}
